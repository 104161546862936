<template>
   <div>
	  <b-row>
		 <b-col md="6"
				offset-md="3">
			<b-card>
			   <b-media v-for="(notification,index) in notifications"
						:key="index"
						class=" opacity-80 border-bottom py-1">
				  <template #aside>
					 <b-avatar
						 :variant="notification.type"
						 size="32"
					 >
						<feather-icon :icon="notification.icon" />
					 </b-avatar>
				  </template>
				  <p class="media-heading m-0">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
				  </p>
				  <p class="d-flex  align-items-center m-0">
					 <small class="m-0 notification-text">{{ notification.subtitle }}</small>
					 <span style="margin:0 5px">-</span>
					 <small
						 class="m-0 notification-text"
						 style="font-size: 11px;">
						{{ dateConvert(notification.expiryDate) }}
					 </small>
				  </p>
			   </b-media>
			</b-card>
		 </b-col>
	  </b-row>
   </div>
</template>

<script>
import caldoSettings from "@/caldoSettings";
import {
   BCard,
   BRow,
   BCol,
   BAvatar,
   BBadge,
   BButton,
   BFormCheckbox,
   BLink,
   BMedia,
   BNavItemDropdown,
} from 'bootstrap-vue'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";

export default {
   components: {
	  BCard,
	  BRow,
	  BCol,
	  BNavItemDropdown,
	  BBadge,
	  BMedia,
	  BLink,
	  BAvatar,
	  VuePerfectScrollbar,
	  BButton,
	  BFormCheckbox,
   },
   name: "notification-all",
   data() {
	  return {
		 notifications: [],

	  }
   },

   methods: {
	  dateConvert: function (date) {
		 return moment(date).format('DD-MM-YYYY');
	  },
   },
   mounted() {
	  console.log(this.$store.getters.getNotification);
	  this.$store.getters.getNotification.forEach(el => {

		 this.notifications.push(
			 {
				id: el.hash,
				title: el.title,
				subtitle: caldoSettings.getTypeNotification(el.type),
				type: caldoSettings.getColorNotification(el.type),
				icon: caldoSettings.getIconNotification(el.type),
				expiryDate: el.expiryDate,
				notification_read: el.notification_read,
			 }
		 )
	  })
   }
}


</script>

<style scoped>
.notification-text {
   color: #b9b9c3;
}
</style>